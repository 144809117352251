import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    // modules: {
    // },

    // This is root store ---------------
    state: {
        myNotifications: [],
        unreadMessage: []
    },
    getters: {
        myNotifications: (state) => {
            return state.myNotifications;
        }
    },
    mutations: {
        setMyNotifications (state, value) {
            state.myNotifications = value;
        },
        setUnreadMessage (state, value) {
            state.unreadMessage = value;
        },
        updateAfterClick (state, value) {
            const index = state.myNotifications.findIndex(m => m.id == value.id);
            if (index != -1) {
                state.myNotifications[index].isRead = true;
                state.unreadMessage = state.myNotifications.filter(n => !n.isRead);
            }
        }
    },
    actions: {
        setMyNotifications: ({ commit }, value) => {
            commit('setMyNotifications', value);
            commit('setUnreadMessage', value.filter(n => !n.isRead));
        },
        updateAfterClick: ({ commit }, value) => {
            commit(('updateAfterClick'), value);
        }
    }
});