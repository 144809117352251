/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import jsonwebtoken from 'jsonwebtoken';
import axios from 'axios';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MainTemplate',
    component: () => import(/* webpackChunkName: "main-template" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
      },
      {
        path: 'services/:name',
        name: 'ServicesDetails',
        component: () => import(/* webpackChunkName: "services-details" */ '../views/service/Details.vue')
      },
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/announcement',
        name: 'Announcement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "announcement" */ '../views/Announcement.vue')
      },
      {
        path: '/announcement/home',
        name: 'AnnouncementHome',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "announcement-home" */ '../views/announcement/Home.vue')
      },
      {
        path: '/announcement/:id',
        name: 'AnnouncementDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "announcement-details" */ '../views/announcement/Details.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
      },
      {
        path: '/status',
        name: 'Status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue')
      },
      {
        path: '/order/home',
        name: 'OrderHome',
        meta: {
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "order-home" */ '../views/order/Home.vue')
      },
      {
        path: '/my-order',
        name: 'MyOrder',
        meta: {
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "order-home" */ '../views/MyOrder.vue')
      },
      {
        path: '/order/:id',
        name: 'OrderDetails',
        meta: {
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "order-details" */ '../views/JobDetails.vue')
      },
      {
        path: '/my-profile',
        name: 'MyProfile',
        component: () => import(/* webpackChunkName: "my-profile" */ '../views/MyProfile.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
      },
      {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
      },
      {
        path: '/register',
        name: 'Register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const jwt = localStorage.getItem('jwt') ? localStorage.getItem('jwt') : null;
  if (jwt) { // handle expire!
    const decoded = jsonwebtoken.decode(jwt);
    const expireTime = decoded.exp;
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    if (expireTime <= currentTime) {
      const name = to.name;
      localStorage.removeItem('jwt');
      return next({ name: 'Login', params: { nextUrl: name } });
    }
  }

  // set global header
  axios.defaults.headers = {
    Authorization: 'Bearer ' + jwt
  };

  // prevent login page after sign in already.
  if ('Login' === to.name && jwt) {
    return next({ name: 'Home' });
  }

  // require authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!jwt) {
      return next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    }
  }
  next();
});
export default router;
